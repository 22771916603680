import idancecloud from 'assets/projects/idancecloud-logo.png';
import onlineReportingLogo from 'assets/projects/onlinereporting-logo.png';
import arthurmurrayLogo from 'assets/projects/arthurmurray-logo.png';
import localtunityLogo from 'assets/projects/localtunity-logo.png';
import printlysLogo from 'assets/projects/printlys-logo.png';
import minglokLogo from 'assets/projects/minglok-logo.png';
import jmenuLogo from 'assets/projects/jmenu-logo.png';
import diditLogo from 'assets/projects/didit-logo.png';

export const projects = [
  {
    title: 'iDanceCloud',
    yearLaunched: 2017,
    sourceLink: '',
    viewLink: 'https://demo.idancecloud.com',
    description: 'iDanceCloud assists dance studios in their day-to-day scheduling, client & staff management, and reporting needs.',
    logo: idancecloud,
  },
  {
    title: 'Arthur Murray Online Reporting',
    yearLaunched: 2015,
    sourceLink: '',
    viewLink: 'https://reporting.arthurmurray.com',
    description: 'Over 250 Arthur Murray franchisee dance studios are required to submit weekly reports to this portal.',
    logo: onlineReportingLogo,
  },
  {
    title: 'Localtunity',
    yearLaunched: 2014,
    sourceLink: '',
    viewLink: 'https://localtunity.com/',
    description: 'Encourages customer engagement with local businesses via the mobile rewards & loyalty system.',
    logo: localtunityLogo,
  },
  {
    title: 'arthurmurray.com',
    yearLaunched: 2016,
    sourceLink: '',
    viewLink: 'https://arthurmurray.com',
    description: 'Corporate website for the international dance studio.',
    logo: arthurmurrayLogo,
  },
  {
    title: 'Printlys',
    yearLaunched: 2013,
    sourceLink: '',
    viewLink: 'https://eddm.printlys.com',
    description: 'Place orders for direct mailers and target the exact carrier routes you wish to send them to.',
    logo: printlysLogo,
  },
  {
    title: 'JustMenu',
    yearLaunched: 2013,
    sourceLink: 'https://github.com/jfortunato/justmenu',
    viewLink: 'http://jfortunato.github.io/justmenu/#/order-online',
    description: 'Open source online ordering system for restaurants.',
    logo: jmenuLogo,
  },
  {
    title: 'Ming Lok - Online Ordering',
    yearLaunched: 2012,
    sourceLink: '',
    viewLink: 'https://minglokpitman.com/order_online.php',
    description: 'Online ordering system for a chinese restaurant in New Jersey.',
    logo: minglokLogo,
  },
  {
    title: 'Didit!',
    yearLaunched: 2011,
    sourceLink: '',
    viewLink: '',
    description: 'Simple & clean to-do list manager app for Android.',
    logo: diditLogo,
  },
  {
    title: 'Geomail',
    yearLaunched: 2016,
    sourceLink: 'https://github.com/jfortunato/geomail',
    viewLink: '',
    description: 'Basic PHP package that will send an email to the closest geographic location from a list of various locations.',
    logo: '',
  },
];
